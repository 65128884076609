.sdx-card-image {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 15rem;
}

.card-image {
    height: 200px;
    width: auto;
}

.sdx-input-textarea {
    display: block;
    width: 100%;
    margin: 0 auto;
}

@media (min-width: 768px) {
    .sdx-input-textarea {
        width: 50%;
    }

    .card-image {
        height: 400px;
        width: auto;
    }
}
