/* Container for the webcam */
.webcam-container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
}

/* Styling for the webcam video */
.webcam-video {
    width: 512px;
    height: 512px;
    object-fit: cover; /* Ensure the video covers the entire element */
}

/* Styling for the generated image */
.generated-image {
    width: 512px;
    object-fit: cover; /* Ensure the video covers the entire element */
}

/* Silhouette overlay */
.silhouette-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.silhouette-image {
    width: 100%; /* Ensures it scales with the container */
    max-width: 512px; /* Max size for larger screens */
}

/* Screenshot preview styles */
.screenshot-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .webcam-video {
        width: 100%;
        height: auto;
        object-fit: cover; /* Ensure the video covers the entire element */
    }

    .generated-image {
        width: 100%;
        height: auto;
        object-fit: cover; /* Ensure the video covers the entire element */
    }

    .silhouette-image {
        width: 100%; /* Ensure the image is responsive */
        max-width: 100%; /* Limit the max width to 100% */
    }
}
